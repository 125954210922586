@import "variables";
@import "bootstrap";
@import "bootstrap/theme";


/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

/* Custom mixins */
	@import "./mixins.scss";

/* All Pages - Tools */
	@import "./pages/_all-page-tool-styles.scss";


/* Header */
	// @import "../blocks/header/fw-small-3-col-default/style.scss";
	// @import "../blocks/header/fw-small-3-col-inverse/style.scss";
	// @import "../blocks/header/fw-small-modern-nav-cta-map-icon/style.scss";
	@import "../blocks/header/fw-tall-2-col/style.scss";


/* START of Front Page Blocks */
	// Splash Area
		// @import "../blocks/splash/fw-bg-vid-with-content-plus-vid-area/style.scss";
		// @import "../blocks/splash/fw-fh-banner-with-img-strip/style.scss";
		// @import "../blocks/splash/fw-fh-bg-img-with-hz-contact-form-splash/style.scss";
		// @import "../blocks/splash/fw-fh-bg-img-with-title/style.scss";
		// @import "../blocks/splash/fw-fh-logo-title-content-and-buttons/style.scss";
		// @import "../blocks/splash/fw-fh-one-col-slider-two-title-splash/style.scss";
		// @import "../blocks/splash/fw-fh-splash-parallax-with-mouse/style.scss";
		// @import "../blocks/splash/fw-fh-two-col-splash/style.scss";
		 @import "../blocks/splash/fw-fh-two-col-splash-parallax/style.scss";
		// @import "../blocks/splash/fw-fh-two-col-splash-v2/style.scss";

	// Top Three
		// @import "../blocks/top3/fw-fh-top-three-with-image-hover/style.scss";
		 @import "../blocks/top3/fw-two-title-3-box-with-img/style.scss";
		// @import "../blocks/top4/wm-top-4/style.scss";

	// Main Content
		@import "../blocks/main-content/fw-fh-tri-col-two-image-content-and-list/style.scss";
		// @import "../blocks/main-content/fw-fh-two-image-title-text-list-and-link/style.scss";
		// @import "../blocks/main-content/fw-fh-why-us-news-branding-and-content/style.scss";
		@import "../blocks/main-content/fw-overlap-one-image-with-text-and-link/style.scss";
		// @import "../blocks/main-content/fw-top-three-col-one-main-content-col-two/style.scss";
		// @import "../blocks/main-content/wm-one-image-title-text-list-and-link/style.scss";

	// Special
		@import "../blocks/special/fw-speakers-list-area/style.scss";

	// Video Area
		// @import "../blocks/special/wm-live-video-with-video-play/style.scss";

	// Featured Products
		// @import "../blocks/feat-products/fw-fh-featured-products/style.scss";

	// Flex Sections
		// @import "../blocks/flex/fw-flip-flex-4-col/style.scss";
		// @import "../blocks/flex/fw-one-image-fw-box-title-text-and-link-fw/style.scss";
		// @import "../blocks/flex/fw-one-image-box-title-text-and-link/style.scss";
		// @import "../blocks/flex/fw-overlap-one-image-with-text-and-link/style.scss";
		// @import "../blocks/flex/fw-stacked-expanding-circles-on-squiggle/style.scss";
		// @import "../blocks/flex/fw-stacked-expanding-rectangles-with-titles/style.scss";
		// @import "../blocks/flex/fw-stacked-expanding-squares-with-titles/style.scss";
		// @import "../blocks/flex/fw-two-image-background-forground-img-odd-shapes-title-text-and-link/style.scss";

	// Testimonials
		// @import "../blocks/feat-testimonials/fw-2-col-photo-testimonial/style.scss";
		@import "../blocks/feat-testimonials/fw-fh-one-testimonials-logo-and-image-v2/style.scss";
		// @import "../blocks/feat-testimonials/fw-fh-one-testimonials-logo-and-image/style.scss";
		// @import "../blocks/feat-testimonials/wm-2-col-logo-with-review-and-2-title-area/style.scss";
		// @import "../blocks/feat-testimonials/wm-photo-strip-logo-one-testimonial-with-link/style.scss";
/* END of Front Page Blocks */


/* Contact */
	// @import "../blocks/contact/cw-fh-1col-contact-with-large-map-background/style.scss";
	// @import "../blocks/contact/cw-fh-1col-contact-with-photo-and-large-map-background/style.scss";
	// @import "../blocks/contact/fw-1-col-contact-with-photo/style.scss";
	// @import "../blocks/contact/fw-3-col-info-map-cta/style.scss";
	// @import "../blocks/contact/fw-fh-2-col-contact-with-service/style.scss";
	// @import "../blocks/contact/wm-2-col-contact-block/style.scss";
	 @import "../blocks/contact/fw-wm-3-column-contact-no-map/style.scss";


/* Foot Copyright */
	@import "./pages/_simple-foot-copyright.scss";


/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
/* Single Page Styles */

/* All Sub Page Styles */
	@import "./pages/_sub-page-styles.scss";

/* Blog Pages */
	@import "./pages/_blog-page-styles.scss";

/* Testimonial Page Styles */
	@import "./pages/_testimonials-page-styles.scss";

/* Services Page Styles */
	@import "./pages/_services-page-styles.scss";

/* Contact Page Styles */
	@import "./pages/_contact-us-page-styles.scss";

/* WooCommerce Page Styles */
	@import "./pages/_woocommerce-page-styles.scss";




/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

/* sm - small */
@media (min-width: 768px) {
	/* Look Under Pages/_all-page-tool-styles.scss First */
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

/* md - medium */
@media (min-width: 992px) {
	/* Look Under Pages/_all-page-tool-styles.scss First */
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

/* lg - large */
@media (min-width: 1200px) {
	/* Look Under Pages/_all-page-tool-styles.scss First */
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
