/*
	Block Notes:
		Version:        2.1.0
		Built for:		Template
		Website:		Template

*/


/* * * Start of Tools * * */

body {
	text-rendering: optimizeLegibility;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	padding-top: 0;
	line-height: 1.25;
}

.h-align {
	@include hAlign();
}

.v-align {
	@include vAlign();
}

.this-image {
	background-position: center;
	background-size: cover;
	min-height: 160px;
	border-radius: 3px;
}

.centered-bg-image {
	background-size: cover;
	background-position: center;
}

.no-gutter {
	padding-left: 0;
	padding-right: 0;
}

.alignright {
	float: right;
	margin: 0 0 10px 15px;
}

.alignleft {
	float: left;
	margin: 0 15px 10px 0;
}

hr { 
	border: 0; 
	height: 1px; 
	background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0)); 
	width: 80%;
	margin: 40px auto;
}

/* Fluid Square Boxes */
.square-box{
    position: relative;
    overflow: hidden;
}
.square-box:before{
    content: "";
    display: block;
    padding-top: 100%;
}
.square-content{
    position:  absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

/* Screen Height */
	.screen-height {
		@include screenHeight();
	}

.btn {
	font-family: $headings-font-family;
	margin-top: 14px;
	padding-left: 20px;
	padding-right: 20px;
	font-weight: 800;
	background-image: none !important;
	-webkit-box-shadow: none;
	box-shadow: none;
	-webkit-transition: none;
	transition: none;
}

.btn-primary{
	border: 0px;
    padding: 3px 25px;
    border-radius: 2px;
    font-family:$font-family-serif;
    font-weight: 400;
    font-size: 21px;
}

.light-btn{
	border: 0px;
    padding: 3px 25px;
    border-radius: 2px;
    font-family:$font-family-serif;
    font-weight: 400;
    font-size: 21px;
    background-color:$brand-success;
    color:$gray-base;
    -webkit-box-shadow: 0px 3px 9px 0px rgba(50, 50, 50, 0.81);
-moz-box-shadow:    0px 3px 9px 0px rgba(50, 50, 50, 0.81);
box-shadow:         0px 3px 9px 0px rgba(50, 50, 50, 0.81);
}




/* sm */
@media screen and (min-width: 768px) {
/* Subpage Content Margins */
	#mainTestimonials,
	#servicesSection,
	.sub-page-wrapper article {
		padding: 10px 3% 30px;
	}
	
}

/* md */
@media screen and (min-width: 992px) {
/* Sub Page Header Styels */
	#subPageTagLine {
		margin-top: 100px;
		font-size: 52px;
	}

/* Subpage Content Margins */
	#mainTestimonials,
	#servicesSection,
	.sub-page-wrapper article {
		padding: 10px 4% 30px;
	}

}

/* lg */
@media screen and (min-width: 1200px) {
/* Subpage Content Margins */
	#mainTestimonials,
	#servicesSection,
	.sub-page-wrapper article {
		padding: 15px 7% 30px;
	}

}