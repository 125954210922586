/*
	Block Notes:
		Built for:			Electric Norm
		Website:
		Version: 			2.3.0

*/



/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

/* * * header - Main Header Styles * * */

/* Header Styles */


#mainHeaderWrapper {
	width: 100%;
	font-family: $headings-font-family;
	position: relative;
	z-index: 1;

	// Info
	.navbar-brand {
		padding: 4px 0;
		height: auto;
		float: none;

		img {
			// float: left;
			margin-top: 5px;
			margin-bottom: 3px;
			
			display: inline-block;
			max-width: 100%;
    		max-height: 100px;
			transition: all 0.3s ease-out;
			-webkit-transition: all 0.3s ease-out;
		}

		h1 {
			font-size: 32px;
			float: left;
			margin: 0;
			padding-left: 10px;
			display: none;
			display: block;
			color: $gray-light;
		}
	}

	// Nav
	.head-nav,
	.collapse.in {
		background-color: $brand-primary;
	}
	nav.navbar-inverse {
		background-image: none;
		border: none;
		border-radius: 0;
		background-color: #fff;
		box-shadow: none;
		margin-bottom: 0px;
		-webkit-transition: all 500ms ease;
		transition: all 500ms ease;
		font-family:$font-family-serif;
	}

	.navbar-inverse .navbar-nav > .open > a,
	.navbar-inverse .navbar-nav > .active > a {
		background-image: none;
		box-shadow: none;
		color: $gray-base;
	}

	.navbar-inverse .navbar-nav > li > a {
	    color: #fff;
	}

	.navbar-inverse .navbar-nav > .active > a, .navbar-inverse .navbar-nav > .active > a:hover, .navbar-inverse .navbar-nav > .active > a:focus {
	    color: #fff;
	    background-color: $brand-danger !important;
	}
	.navbar-inverse .navbar-brand, .navbar-inverse .navbar-nav > li > a {
	    text-shadow: none;
	}

	.navbar-inverse .navbar-nav > .open > a, .navbar-inverse .navbar-nav > .open > a:hover, .navbar-inverse .navbar-nav > .open > a:focus{
		background-color: $brand-danger !important;
	}

	.navbar-inverse .navbar-nav > li > a:hover, .navbar-inverse .navbar-nav > li > a:focus {
	    color: #fff;
	    background-color: $brand-danger !important;
	}
	.dropdown-menu{
		border-radius:0px;
	}

	.navbar-inverse .navbar-nav .open .dropdown-menu > li > a {
	    color: #fff;
	}

	.navbar-inverse .navbar-nav .open .dropdown-menu > li > a:hover, .navbar-inverse .navbar-nav .open .dropdown-menu > li > a:focus {
	    color: #fff;
	    background-color: $brand-danger !important;
	}
}
#mainHeaderWrapper.active .navbar-brand img {
    max-height: 44px;
}

.head-phone-cta {
	color:#fff;
	font-family:$font-family-serif;
	font-size: 17px;
	margin-top: 10px;
	font-weight: normal;

	span {
		color: #fff;
		font-size: 12px;
		font-family:$font-family-sans-serif;
		font-weight:normal;
	}
}

// mobile phone and cta - in menu only
.nav.head-phone-cta {
	padding: 10px 15px;
}
#mainHeaderWrapper .head-logo{
	text-align:center ;
}

/* sm */
@media screen and (min-width: 768px) {

	#mainHeaderWrapper{
		.navbar-brand {

			img {
				margin-left: 10px;
			}
		}
	}
	#mainHeaderWrapper .head-logo{
		text-align:left ;

	}
	.head-phone-cta {
		color: $gray-base;
		margin-right:15px;

		span {
			color: $brand-primary;
		}
	}

	.navbar-right {
	    margin-right: 15px;
	}

	.admin-bar #mainHeaderWrapper {
		margin-top: 46px !important;
	}

	body {
		padding-top: 110px; // adjust to height of #mainHeaderWrapper
	}

// Sets Everyting to transparent
	#mainHeaderWrapper {
		margin-bottom: 0px;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 10;

	// Navbar Styles
		.navbar-nav > li > a {
			padding-top: 2px;
			padding-bottom: 3px;

		}

	// Changes Header Branding
		.navbar-brand h1 {
			display: none;
		}


		.head-phone-cta {
			font-size: 24px;

			span {
				font-size: 16px;
			}
		}

		nav.navbar-inverse {
		}

		.navbar-brand h1 {
		}
		// Nav
		.head-nav,
		.collapse.in {
		}
	}


// Styles creats correct styleing during active or hover
	#mainHeaderWrapper:hover nav.navbar-inverse,
	#mainHeaderWrapper.active nav.navbar-inverse {
	}

	#mainHeaderWrapper:hover .navbar-brand h1,
	#mainHeaderWrapper.active .navbar-brand h1 {
		display: block;
	}
	// Nav
	#mainHeaderWrapper:hover .head-nav,
	#mainHeaderWrapper.active .head-nav,
	#mainHeaderWrapper .collapse.in {
	}

}

/* md */
@media screen and (min-width: 992px) {
	#mainHeaderWrapper {
		.navbar-inverse .navbar-nav .open .dropdown-menu > li > a {
		    color: $gray-base;
		}
		.navbar-inverse .navbar-nav .open .dropdown-menu > li > a:hover, .navbar-inverse .navbar-nav .open .dropdown-menu > li > a:focus {
		    color: $gray-base;
		    background-color: #77231b !important;
		}
	}

	.admin-bar #mainHeaderWrapper {
		margin-top: 32px !important;
	}
	#mainHeaderWrapper {
		.navbar-brand {
			padding: 4px 0 4px 15px;
		}
	}

	#mainHeaderWrapper .head-logo{
		text-align:left ;
	}

	

}

/* lg */
@media screen and (min-width: 1200px) {

}
