/*
	Block Notes:
		Version: 			2.2.0
		Built for:			Template
		Website:			Template

*/


/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

/* * * Front Page - Main Content Area Styles * * */

#mainContent {
	text-align: center;
	
	.screen-height {
		min-height: 600px;
	}

	#mainContentLogo {
		width: auto;
		margin: 10px auto;
	}
	
	.this-list {
		text-align: left;
		font-weight: bold;

		.far {
			color: $gray-dark;
		}
	}
}





/* sm */
@media screen and (min-width: 768px) {
	#mainContent {
		.screen-height {
			min-height: 750px;
		}

		.main-cta {
			padding-right: 10px;
			text-align: right;
		}
	}
}

/* md */
@media screen and (min-width: 992px) {
	
}

/* lg */
@media screen and (min-width: 1200px) {

}
