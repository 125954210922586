/*
	Block Notes:
		Version: 		2.1.0
		Built for: 		Oregon Coast Comics
		Website: 		https://oregoncoastcomics.com/

*/

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

/* * * Front Page - Featured Testimonials * * */

#featuredTestimonialsArea {
	background-color: $brand-primary;
	color: #fff;
	min-height: 600px;
	text-align:center;

	.featured-testimonal {
		padding: 10px 2%;
	}

	p{
		font-weight: normal;

		a{
			color:  $brand-light;
		}
	}

	h3,	.h3 {
		color: #fff;
		text-align:center;
		margin:0 0 10px 0;
		font-size:35px;
		font-weight: normal;
		font-family:$font-family-serif;
	}
}

div.featured-testimonal:nth-child(2) {
	display: none;
}


#featuredTestimonialsImg {
	background-size: cover;
	background-position: center;
	text-align: center;
}

	

	#featuredTestimonialLogo {
		height: auto;
		width: 80%;
		max-width: 505px;
		padding: 0;
		margin: 0 auto;
	}

#featuredTestimonialsArea .review-stars {
	color:  $brand-success;
	font-size: 20px;
    margin: 0 0 10px 0;
}

#featuredTestimonialsArea .review-name {
	color: #fff;
	text-align:center;
}
#featuredTestimonialsCTA {
	padding-top: 30px;

	h3{
		color: #fff;
		text-align:center;
		margin:0;
		font-size:22px;
		font-weight: normal;
		font-family:$font-family-serif;
	}
}




/* sm */
@media screen and (min-width: 768px) {
/* Featured Testimonials */
	#featuredTestimonialsCTA {

		h3{
			font-size:22px;
		}
	}
}

/* md */
@media screen and (min-width: 992px) {
	
}

/* lg */
@media screen and (min-width: 1200px) {
/* Featured Testimonials */
	#featuredTestimonialsArea .featured-testimonal {
		padding: 10px 12%;
	}
}