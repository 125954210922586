/*
	Block Notes:
		Version:        2.1.0
		Designed By:    Travis Wicks
		Built By:       Travis Wicks

*/


/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

/* Contact Us Page - Contact Page Styles */


#contactInfoAll h2.contact-info,
#contactInfoAll h3.contact-info,
#contactInfoAll h4.contact-info {
	margin-top: 4px;
	margin-bottom: 20px;
	color: $gray;
}

#contactInfoAll h6.contact-title {
	margin-top: 5px;
	margin-bottom: 0;
}

#contactInfoAll .social-icon {
	font-size: 32px;
	margin: 8px 7px 5px;
}

#contactFormAll {
	padding-top: 20px;
	background-color: $gray-lighter;
	border-radius: 3px;
}






/* sm */
@media screen and (min-width: 768px) {

}

/* md */
@media screen and (min-width: 992px) {
	
}

/* lg */
@media screen and (min-width: 1200px) {
	
}