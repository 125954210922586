/*
	Block Notes:
		Version: 			2.1.0
		Built for:			Prana Planet
		Website:

*/

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

/* * * Front Page - Top 3 Area - Top Three Styles * * */

#topThreeTitles {
	padding-top: 60px;
	padding-left:2%;
	padding-right:2%;

	h3{
		font-family:$font-family-serif;
		font-size:22px;
		color:$brand-light;
		margin: 0 0 5px 0;
    	letter-spacing: 1px;
	}
	h2{
		font-family:$font-family-serif;
		font-size:32px;
		color:#fff;
		margin: 0 0 0 0;
    	letter-spacing: 1px;
	}
}

.top-box-wrap {
	padding: 0 0 15px;
}

.top-box-wrap .box {
	padding: 40px 20px;
	background-color: $brand-light;
	height: 415px;
	max-width:300px;
	margin:auto;
	color:$gray-base;

	h3{
		font-family:$font-family-serif;
		margin: 0 0 10px 0;
	}
}

.top-box-wrap a {
	text-decoration: none;
}

.top-box-wrap .box .this-text {
	padding: 10px 0 40px;
	display: block;
}

.top-box-wrap .box .this-image {
	width: 160px;
	height: 160px;
	margin: 0 auto;
	border-radius: 50%;
}




/* sm */
@media screen and (min-width: 768px) {
/* Top 3 */
	.top-box-wrap .box .this-image {
		width: 184px;
		height: 184px;
		margin: 0 auto;
		border-radius: 50%;
	}



	#topThreeTitles {
		h3{
			font-size:32px;
			letter-spacing: 2px;
		}
		h2{
			font-size:42px;
			letter-spacing: 4px;
		}
	}
}

/* md */
@media screen and (min-width: 992px) {
/* Top 3 */
	.top-box-wrap {
		padding: 10px 12% 40px;
	}
}

/* lg */
@media screen and (min-width: 1200px) {
/* Top 3 */
	.top-box-wrap {
		padding: 40px 2% 80px;
	}
}