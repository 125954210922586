
@mixin screenHeight($min: 600px) {
	height: 100vh;
    min-height: $min;
}

@mixin hAlign {
	position: relative;
	left: 50%;
	-webkit-transform: translateX(-50%);
		-ms-transform: translateX(-50%);
			transform: translateX(-50%);
}

@mixin vAlign {
	position: relative;
	top: 50%;
	-webkit-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
			transform: translateY(-50%);
}
