/*
	Block Notes:
		Version:        	2.1.0
		Designed By:    	Travis Wicks
		Built By:       	Travis Wicks

*/


/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

/* * * Testimonials Page * * */
#testimonialsWrapper .review-stars {
	font-size: 22px;
	color: $brand-success;
}

#testimonialsWrapper .review-name {
	font-size: 24px;
	color: $brand-success;
	font-family: $headings-font-family;
}

/* BlockQuote */
#testimonialsWrapper blockquote {
	color: $brand-success;
}



/* sm */
@media screen and (min-width: 768px) {

}

/* md */
@media screen and (min-width: 992px) {
	
}

/* lg */
@media screen and (min-width: 1200px) {
	
}