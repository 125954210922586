/*
	Block Notes:
		Version:        	2.1.0
		Designed By:    	Travis Wicks
		Built By:       	Travis Wicks

*/


/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

/* * * All Sub-pages - Sub Page Wrapper Styles  * * */

.sub-page-wrapper {
	padding-bottom: 30px;
	margin-top: 185px;
}

.sub-page-wrapper article {
	padding-bottom: 30px;
}

.sub-page-container {
	min-height: 550px
}

/* Sub page headers */

#subPageHead {
	position: relative;
	height: 500px;
	background-size: cover;
	background-position: center;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 0;
	border-bottom: 5px solid $brand-danger;
}

	#subPageHead:after {
		content: '';
		display: block;
		position: relative;
		bottom: 0;
		left: 0;
		height: 100%;
		width: 100%;
		background-color: rgba( 0, 0, 0, .5 );
	}

#subPageTagLine {
	margin-top: 0;
	font-size: 32px;
	color: #fff;
	position: relative;
	z-index: 1;
	text-align: right;
	font-family: $headings-font-family;
}


.sub-page-container {
	background-color: #fff;
	position: relative;
}

.sfpbk-list-product{
	.add_to_cart_button {
		padding: 5px 25px;
		border-radius: 2px;
		font-family: $headings-font-family;
		font-weight: normal;
		font-size: 21px;
		height: 35px;
		color:$kbd-color;
		display:inline-block;
		border:none;
		background-color:$brand-primary;
		text-decoration: none;
		&:hover{
			background-color:#2a3221;
		}
	}
}




/* sm */
@media screen and (min-width: 768px) {

}

/* md */
@media screen and (min-width: 992px) {
	
}

/* lg */
@media screen and (min-width: 1200px) {
	
}