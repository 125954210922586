/*
    Block Notes:
        Version:    2.2.0
        Built for:  Template
        Website:	Template

*/

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

/* * * All Pages - Foot Copyright and Logo * * */

#footLogo {
	padding-top: 30px;
	padding-bottom: 30px;
}

#footLogo img {
	width: 100%;
	max-width: 220px;
}

#footCopyright {
	padding-top: 35px;
	padding-bottom: 35px;
	line-height:30px;
	font-size:15px;
	color: $brand-light;
	background-color:$brand-primary;
}

#footCopyright a {
	color: $brand-light;
	display:block;
}





/* sm */
@media screen and (min-width: 768px) {
	#footLogo img {
		width: auto;
	}

	#footCopyright a {
		display:inline-block;
	}
}

/* md */
@media screen and (min-width: 992px) {
	
}

/* lg */
@media screen and (min-width: 1200px) {
	
}