/*
	Block Notes:
		Built for: 		Prana Planet
		Website: 		Not online Yet
		Version: 		2.2.0

*/


/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

/* * * All Pages - Foot Contact Block * * */

#contactBlockWrapper {
	background-size: cover;
	background-position:bottom center;
	background-attachment: fixed;
	position: relative;
	padding-top: 20px;
	padding-bottom: 0px;
	overflow:hidden;
}



#contactArea {
	text-align: center;
	position: relative;
	float: none;
	margin: 0 auto;
	color:$gray-base;

	h2{
		font-family:$font-family-serif;
		color:$brand-primary;
		font-size:32px;
		margin:0 0 20px 0;
	}
	h4{
		font-family:$font-family-serif;
		color:$brand-primary;
		font-size:22px;
		margin: 20px 0 0 0;
	}

	h3{
		font-family:$font-family-serif;
		color:$gray-base;
		text-align:left;
		font-size:22px;
		margin: 0 0 20px 0;
	}
}

#contactBlockWrapper a {
	color:$gray-base;
}


#contactBlockWrapper .this-text {
	padding-top: 3px;
	padding-bottom: 3px;
	margin: 3px 5%;
}

#contactBlockWrapper a.social-icon .fab {
	font-size: 38px;
	margin: 20px 5px;
	color:$brand-danger;
}

#mapButton {
	padding:15px 0;
	text-align:center;
}
#mapButton .btn-primary{
	color:#fff;
	max-width:500px;
	margin:0 auto;
}





#footLogo img {
	max-width:320px;
	width:100%;
}

.address_info{
	padding:0 15px;
	text-align:left;

	p{
		font-size:15px;
		margin:0;
	}
	p i{
		color: $brand-danger;
	    font-size: 15px;
	    margin: 0 5px 0 0;
	}

	.phone {
		font-size: 15px;
		padding-left:40px;
		color:$gray-base;
		margin:0 0 20px 0;
	}
	.email {
		font-size: 15px;
		padding-left:40px;
		margin:0 0 20px 0;
	}
	.email a {
		color:$gray-base !important;
	}
	.address {
	margin:0 0 20px 0;
		font-size: 15px;
		padding-left:40px;
		color:$gray-base ;
	}
}

/* sm */
@media screen and (min-width: 768px) {
/* Foot Contact Block */
	.address_info{
		padding:0 35px;
		text-align:left;
	}
	#contactBlockWrapper .this-text {
		padding: 3px 7%;
	}
	#contactBlockWrapper {
		padding-top: 60px;
	}
	#contactArea {
		padding-top: 14px;
	}

	#contactBlockWrapper .this-text {
		padding: 3px 7%;
	}
	#contactArea {

		h2{
			font-size:42px;
		}
		h4{
			font-size:32px;
		}

	}

}

/* md */
@media screen and (min-width: 992px) {

	#contactArea {
		h2{
			font-size:42px;
			margin:0 0 40px 0;
		}
		h4{
			font-size:28px;
		}
	}	
}

/* lg */
@media screen and (min-width: 1200px) {
/* Foot Contact */
	#contactBlockWrapper {
		padding-top: 40px;
    padding-bottom: 20px;
	}

	#contactBlockWrapper .this-text {
		padding: 10px 14%;
	}
}