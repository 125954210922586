/*
	Block Notes:
		Version:        2.1.0
		Designed By:    Travis Wicks
		Built By:       Travis Wicks

*/

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

/* * * Blog Page - Blog Styels * * */

.sub-page-wrapper header.blog-list {
	background-image: none;
	background-repeat: none;
	background-position: none;
	background-size: none;
	position: relative;
	height: auto;
	margin-bottom: 0;
}

.attachment-featured-image {
	width: 100%;
	height: auto;
}

.meta-col {
	text-align: right;
}

.blog .entry-date {
	display: block;
}

.byline {
	display: none;
}
time.updated {
	display: none;
}

.screen-reader-text {
	display: none;
}

.blog-entry-footer {
	border-top: 2px solid $gray-lighter;
	padding-top: 40px;
	margin-top: 20px;
}

/* Single Blog Page */

#blogBackBtn {
	padding-bottom: 14px;

	.btn {
		margin-top: 0px;
	}
}

#blogImg {
	padding: 0px 0px 15px;
}

.categories-tags-links {
	display: none;
}

/* Blog List */
.blog-list {
	.entry-title {
		text-align: left;
		font-size: 28px;
	}
}
.blog-sidebar {
	#sidebar {
		li.widget {
			list-style: none;
		}

		.widget-title {
			font-size: 20px;
		}

		.widget_recent_entries {
			ul {
				padding: 0px 0px 0px 2px;
				margin: 0px;

				li {
					list-style: none;

					a,
					.post-date {
						vertical-align: middle;
					}

					.post-date {
						font-size: 12px;
						color: $gray-light;
					}
				}
			}
		}
	}
}
.blog-navigation {
	padding-top: 15px;
	padding-bottom: 15px;
}
.blog-pagination {
	.pagination {
		> li {
			> a,
			> span {
				float: none;
			}
		}
	}
}


/* sm */
@media screen and (min-width: 768px) {

}

/* md */
@media screen and (min-width: 992px) {
	#blogImg {
		padding-right: 15px;
	}
}

/* lg */
@media screen and (min-width: 1200px) {
	
}