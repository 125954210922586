/*
	Block Notes:
		Version: 			1.0.0
		Built for:			
		Website:			

*/

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

/* * * Front Page - Splash Area * * */

#productsList{
	padding-top: 50px;
	padding-bottom: 20px;

	h2{
		font-family:$font-family-serif;
		font-size:50px;
		color:$gray-base;
		margin: 0 0 35px 0;
	}
	.product{
		padding:0 2%;

		.product-card{
			min-height:450px;
			background-color:#f2f2f2;
			padding: 40px 20px;
			max-width: 300px;
			margin:0 auto 40px;
			position: relative;

			h4{
				color:$gray-base;
				margin: 25px 0 5px 0;
			}

			.this-img{
				border-radius: 100%;
				height: 200px;
				width: 200px;
				overflow: hidden;
				margin: auto;
				background-size: cover;
				background-position: center;
			}

			.product-locations a {
				background-color: $brand-primary;
				position: absolute;
				bottom: 0;
				left: 0;
				display: block;
				width: 100%;
				color: #fff;
				padding: 8px;
			}

			.product-locations a:hover {
				background-color: $brand-danger;
			}
		}
	}
}




/* sm */
@media screen and (min-width: 768px) {

}

/* md */
@media screen and (min-width: 992px) {

}

/* lg */
@media screen and (min-width: 1200px) {
	
}