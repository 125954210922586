/*
	Block Notes:
		Version: 		2.1.0
		Built for: 		Oregon Coast Comics
		Website: 		https://oregoncoastcomics.com/

	Site with Parallax example: http://pixelcog.github.io/parallax.js/

*/



/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

/* * * Front Page - Splash Area * * */

#splashArea.screen-height {
	min-height: 500px !important;
}
#splashArea .row {
	position: relative;
	height: 100%;
}

#splashBgImg {
	height: 30%;
	background-position: center;
	background-size: cover;
}


#splashContent {
	height: 70%;
	color: #fff;
	background-position: center bottom;
	background-repeat: repeat-x;
	text-align: center;

	.this-text{
		max-width:410px;
		color:$gray-base;
		margin: auto;
	}
	.v-align {
    	top: 25%;
    }
}

#mainLogo {
	width: 90%;
	max-width: 433px;
	margin: 10px auto;
}

#splashContent .this-text {
	padding: 0;
}

a#splashNext {
	color: #fff;
	font-size: 47px;
	display: none;
}

a#splashNext i{
    top: -50px;
    position: relative;
}
#mainTagline {
	color: #fff;
	font-size: 24px;
}




/* sm */
@media screen and (min-width: 768px) {
/* Splash Area */
	#splashBgImg {
		height: 100%;
	}
	#splashContent {
		height: 100%;

		.v-align {
	    	top: 50%;
	    }
	}

}

/* md */
@media screen and (min-width: 992px) {
/* Splash Area */
	#mainLogo {
		width: 100%;
		max-width: 433px;
		margin: 60px 0 10px 0;
	}
	a#splashNext {
		margin: 0 0 0 -49.5vw;
	}
	a#splashNext {
		display: block;
	}
}

/* lg */
@media screen and (min-width: 1200px) {
	
}