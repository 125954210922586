/*
	Block Notes:
    Version:        2.1.0
		Designed By:    Travis Wicks
		Built By:       Travis Wicks

*/



/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

/* * * Services Page - Open Close Text Areas - Works with JS * * */

#Sections {
  position: relative;
}

.section {
  float: left;
  height: auto;
  clear: both;
  width: 100%;
  margin-bottom: 10px;
  position: relative;
}

.section h3 {
  cursor: pointer;
}

.section .details {
  border: 1px solid $gray-lighter;
  border-top: 0px;
  padding: 10px;
  box-shadow: 0px 0px 5px $gray-lighter inset;
}

.section .details.hidden {
  display: none;
}

#Sections .this-img {
	
	float: right;
	margin: 0 0 10px 15px;
}





/* sm */
@media screen and (min-width: 768px) {

}

/* md */
@media screen and (min-width: 992px) {
	
}

/* lg */
@media screen and (min-width: 1200px) {
	
}