/*
	Block Notes:
		Version:        	2.1.0
		Designed By:    	Travis Wicks
		Built By:       	Travis Wicks

*/




/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

/* WooCommerce Pages - All store pages */

#storeMenu {
	margin-top: 10px;
}

#storeLinks {
	margin-top: 12px;
}

.woocommerce div.product {
	.variations {
		.label {
			color: inherit;
		}
	}

	.product_title {
		margin-top: 14px;
		font-family: $headings-font-family;
	}
}

#shopCategoryDropdown {
	margin-top: 12px;
}

.woocommerce ul.products li.product .woocommerce-loop-category__title, 
.woocommerce ul.products li.product .woocommerce-loop-product__title, 
.woocommerce ul.products li.product h3 {
	font-family: $headings-font-family;
}

.woocommerce div.product {
	margin-top: 30px;
}





/* sm */
@media screen and (min-width: 768px) {

}

/* md */
@media screen and (min-width: 992px) {
	
}

/* lg */
@media screen and (min-width: 1200px) {
	
}