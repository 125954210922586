/*
	Block Notes:
		Version: 		2.1.0
		Built for:		Template
		Website:		Template

*/


/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

/* * * Front Page - main Sections * * */
#mainContent {
	padding-top:60px;

	.this-content{
		margin-left:0;
		margin-right:0;
		text-align:left;
		padding:0;
	}

	.content-wrap{
		margin-top:30px;
	}

	.this-list{
		margin: 0 0 0 14px;
		font-weight: normal;

		li{
			margin:0 0 5px 0;
		}

		.far{
			color:$brand-danger;
			top: -1px;
		}
	}

	h4{
		font-family:$font-family-serif;
		font-size:22px;
		color:$brand-primary;
		margin: 0 0 5px 0;
    	letter-spacing: 1px;
	}
	h2{
		font-family:$font-family-serif;
		font-size:32px;
		color:$gray-base;
		margin: 0 0 0 0;
    	letter-spacing: 1px;
	}

	.v-align{
		position: static;
		top: 0;
		-webkit-transform: translateY(0);
		-ms-transform: translateY(0);
		transform: translateY(0);
	}
}

#mainContent .this-image {
	height: 240px;
	border-radius: 0;
	position: relative;
	z-index: 1;
}
#mainContent .this-content {
	height: auto;
	position: relative;
	z-index: 1;
}
#mainContent .this-content .main-card {
	background-color:#ebeee6;
	padding: 20px;
}


/* sm */ 
@media screen and (min-width: 768px) {
/* main Sections */


	#mainContent {

		h4{
			font-size:32px;
			letter-spacing: 1px;
		}
		h2{
			font-size:42px;
			letter-spacing: 2px;
		}
	}

	

	
}

/* md */
@media screen and (min-width: 992px) {
/* main Sections */

	#mainContent .this-image,
	#mainContent .this-content {
		height: 450px;
	}

	#mainContent {
		.this-content{
			margin-left:-15px;
			margin-right:-15px;
		}
	}
	#mainContent {
		height: 600px;
		margin-bottom: 80px;

	}

	#mainContent .this-image {
		position: absolute;
		z-index: 1;
		top: 0;
		right: 0;
		width:60%;
	}

	#mainContent .this-content {
		position: absolute;
		z-index: 3;
		top: 0;
		left: 0;
	}
	#mainContent .this-content .main-card {
		background-color: #ebeee6;
		padding: 40px 60px;
		border-right: 10px solid $brand-primary;

		h3{
			font-family:$font-family-serif;
			font-size:23px;
			color:$gray-base;
			margin: 0 0 10px 0;
		}
	}

	#mainContent{
		.v-align{
			position: relative;
			top: 50%;
			-webkit-transform: translateY(-50%);
			-ms-transform: translateY(-50%);
			transform: translateY(-50%);
		}

		.this-content{
			margin-left:0;
			margin-right:0;
			text-align:left;
			padding:0;
		}
	}


	
}

/* lg */
@media screen and (min-width: 1200px) {
/* main Sections */

}


